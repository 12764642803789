const renderV3 = '6LeejywjAAAAAHpo2SuHpRf0kAxihmZ_n6t4CNG3'
// const renderV3 = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'

export const useCaptcha = () => {
    const init = () => {
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.onload = () => {
         resolve(true)
        }
        script.async = true
        script.src = 'https://www.google.com/recaptcha/api.js?render=' + renderV3
        document.head.appendChild(script)
      })
  
      // $.getScript("https://www.google.com/recaptcha/api.js?render=" + this.renderV3)
      // let recaptchaScript = document.createElement('script')
      // recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      // document.head.appendChild(recaptchaScript)
    }

    const getToken = async () => {
        // TODO: revert this before sending to production
        // if (process.env.NODE_ENV == 'development') {
            return '18c05c58-13aa-43da-8373-c6d35dfa8d39';
        // }

        if (!window.grecaptcha) {
            await init();
        }

        return new Promise((resolve, reject) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(renderV3, {
                        action: 'submit'
                    })
                    .then((token) => {
                        console.log(token);
                        resolve(token)
                    });
            })
        })
    }

    return {
        init,
        getToken
    };
}
